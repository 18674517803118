<template>
  <b-modal
    id="clinic-create-confirm-modal"
    title="Setting up an autoclave on site"
    cancel-title="CANCEL"
    ok-title="CONFIRM"
    hide-header-close
    centered
    :ok-disabled="!isConfirm"
    @ok="submit"
    @cancel="cancel"
  >
    <div class="px-4">
      <p class="mb-4">
        To make sure you recieve real-time information from an autoclave,
        entering your Serial no. on to the device is required for linking purposes.
        Your Serial no. is listed below.
        You can always go back to “Corporate Information” under the “User Profile” page to look it up.
        Serial no.:
      </p>
      <p>
        <span v-for="(a, i) in autoclaveList" :key="i">
          Autoclave {{ i+1 }}: {{ a.serial_number }}<br>
        </span>
      </p>

      <b-form-checkbox v-model="isConfirm">
        I accept the terms and use
      </b-form-checkbox>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data () {
    return {
      isConfirm: false,
      autoclaveList: []
    }
  },
  methods: {
    show (list) {
      this.autoclaveList = list
      this.$bvModal.show('clinic-create-confirm-modal')
      this.isConfirm = false
    },
    hide () {
      this.$bvModal.hide('clinic-create-confirm-modal')
    },
    submit () {
      this.$emit('submit')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
