import apiService from '@/common/api.service'

export function getClinicList () {
  return apiService({
    url: '/api/clinic/list',
    method: 'get'
  })
}

export function getSpecifyList (id) {
  return apiService({
    url: '/api/clinic/list/specify',
    method: 'get',
    params: { id }
  })
}

export function getClinicData (id) {
  return apiService({
    url: '/api/clinic',
    method: 'get',
    params: { id }
  })
}
export function createClinic (data) {
  return apiService({
    url: '/api/clinic',
    method: 'post',
    data
  })
}

export function updateClinicInformationData (data) {
  return apiService({
    url: '/api/clinic/information',
    method: 'patch',
    data
  })
}

export function updateClinicOwnerData (data) {
  return apiService({
    url: '/api/clinic/owner',
    method: 'patch',
    data
  })
}
