const countryList = [
  { value: 0, text: 'Taiwan', code: '886' },
  { value: 1, text: 'Afghanistan', code: '93' },
  { value: 2, text: 'Albania', code: '355' },
  { value: 3, text: 'Algeria', code: '213' },
  { value: 4, text: 'American Samoa', code: '1-684' },
  { value: 5, text: 'Andorra', code: '376' },
  { value: 6, text: 'Angola', code: '244' },
  { value: 7, text: 'Anguilla', code: '1-264' },
  { value: 8, text: 'Antarctica', code: '672' },
  { value: 9, text: 'Antigua and Barbuda', code: '1-268' },
  { value: 10, text: 'Argentina', code: '54' },
  { value: 11, text: 'Armenia', code: '374' },
  { value: 12, text: 'Aruba', code: '297' },
  { value: 13, text: 'Australia', code: '61' },
  { value: 14, text: 'Austria', code: '43' },
  { value: 15, text: 'Azerbaijan', code: '994' },
  { value: 16, text: 'Bahamas', code: '1-242' },
  { value: 17, text: 'Bahrain', code: '973' },
  { value: 18, text: 'Bangladesh', code: '880' },
  { value: 19, text: 'Barbados', code: '1-246' },
  { value: 20, text: 'Belarus', code: '375' },
  { value: 21, text: 'Belgium', code: '32' },
  { value: 22, text: 'Belize', code: '501' },
  { value: 23, text: 'Benin', code: '229' },
  { value: 24, text: 'Bermuda', code: '1-441' },
  { value: 25, text: 'Bhutan', code: '975' },
  { value: 26, text: 'Bolivia', code: '591' },
  { value: 27, text: 'Bosnia and Herzegovina', code: '387' },
  { value: 28, text: 'Botswana', code: '267' },
  { value: 29, text: 'Brazil', code: '55' },
  { value: 30, text: 'British Indian Ocean Territory', code: '246' },
  { value: 31, text: 'British Virgin Islands', code: '1-284' },
  { value: 32, text: 'Brunei', code: '673' },
  { value: 33, text: 'Bulgaria', code: '359' },
  { value: 34, text: 'Burkina Faso', code: '226' },
  { value: 35, text: 'Burundi', code: '257' },
  { value: 36, text: 'Cambodia', code: '855' },
  { value: 37, text: 'Cameroon', code: '237' },
  { value: 38, text: 'Canada', code: '1' },
  { value: 39, text: 'Cape Verde', code: '238' },
  { value: 40, text: 'Cayman Islands', code: '1-345' },
  { value: 41, text: 'Central African Republic', code: '236' },
  { value: 42, text: 'Chad', code: '235' },
  { value: 43, text: 'Chile', code: '56' },
  { value: 44, text: 'China', code: '86' },
  { value: 45, text: 'Christmas Island', code: '61' },
  { value: 46, text: 'Cocos Islands', code: '61' },
  { value: 47, text: 'Colombia', code: '57' },
  { value: 48, text: 'Comoros', code: '269' },
  { value: 49, text: 'Cook Islands', code: '682' },
  { value: 50, text: 'Costa Rica', code: '506' },
  { value: 51, text: 'Croatia', code: '385' },
  { value: 52, text: 'Cuba', code: '53' },
  { value: 53, text: 'Curacao', code: '599' },
  { value: 54, text: 'Cyprus', code: '357' },
  { value: 55, text: 'Czech Republic', code: '420' },
  { value: 56, text: 'Democratic Republic of the Congo', code: '243' },
  { value: 57, text: 'Denmark', code: '45' },
  { value: 58, text: 'Djibouti', code: '253' },
  { value: 59, text: 'Dominica', code: '1-767' },
  { value: 60, text: 'Dominican Republic', code: '1-809, 1-829, 1-849' },
  { value: 61, text: 'East Timor', code: '670' },
  { value: 62, text: 'Ecuador', code: '593' },
  { value: 63, text: 'Egypt', code: '20' },
  { value: 64, text: 'El Salvador', code: '503' },
  { value: 65, text: 'Equatorial Guinea', code: '240' },
  { value: 66, text: 'Eritrea', code: '291' },
  { value: 67, text: 'Estonia', code: '372' },
  { value: 68, text: 'Ethiopia', code: '251' },
  { value: 69, text: 'Falkland Islands', code: '500' },
  { value: 70, text: 'Faroe Islands', code: '298' },
  { value: 71, text: 'Fiji', code: '679' },
  { value: 72, text: 'Finland', code: '358' },
  { value: 73, text: 'France', code: '33' },
  { value: 74, text: 'French Polynesia', code: '689' },
  { value: 75, text: 'Gabon', code: '241' },
  { value: 76, text: 'Gambia', code: '220' },
  { value: 77, text: 'Georgia', code: '995' },
  { value: 78, text: 'Germany', code: '49' },
  { value: 79, text: 'Ghana', code: '233' },
  { value: 80, text: 'Gibraltar', code: '350' },
  { value: 81, text: 'Greece', code: '30' },
  { value: 82, text: 'Greenland', code: '299' },
  { value: 83, text: 'Grenada', code: '1-473' },
  { value: 84, text: 'Guam', code: '1-671' },
  { value: 85, text: 'Guatemala', code: '502' },
  { value: 86, text: 'Guernsey', code: '44-1481' },
  { value: 87, text: 'Guinea', code: '224' },
  { value: 88, text: 'Guinea-Bissau', code: '245' },
  { value: 89, text: 'Guyana', code: '592' },
  { value: 90, text: 'Haiti', code: '509' },
  { value: 91, text: 'Honduras', code: '504' },
  { value: 92, text: 'Hong Kong', code: '852' },
  { value: 93, text: 'Hungary', code: '36' },
  { value: 94, text: 'Iceland', code: '354' },
  { value: 95, text: 'India', code: '91' },
  { value: 96, text: 'Indonesia', code: '62' },
  { value: 97, text: 'Iran', code: '98' },
  { value: 98, text: 'Iraq', code: '964' },
  { value: 99, text: 'Ireland', code: '353' },
  { value: 100, text: 'Isle of Man', code: '44-1624' },
  { value: 101, text: 'Israel', code: '972' },
  { value: 102, text: 'Italy', code: '39' },
  { value: 103, text: 'Ivory Coast', code: '225' },
  { value: 104, text: 'Jamaica', code: '1-876' },
  { value: 105, text: 'Japan', code: '81' },
  { value: 106, text: 'Jersey', code: '44-1534' },
  { value: 107, text: 'Jordan', code: '962' },
  { value: 108, text: 'Kazakhstan', code: '7' },
  { value: 109, text: 'Kenya', code: '254' },
  { value: 110, text: 'Kiribati', code: '686' },
  { value: 111, text: 'Kosovo', code: '383' },
  { value: 112, text: 'Kuwait', code: '965' },
  { value: 113, text: 'Kyrgyzstan', code: '996' },
  { value: 114, text: 'Laos', code: '856' },
  { value: 115, text: 'Latvia', code: '371' },
  { value: 116, text: 'Lebanon', code: '961' },
  { value: 117, text: 'Lesotho', code: '266' },
  { value: 118, text: 'Liberia', code: '231' },
  { value: 119, text: 'Libya', code: '218' },
  { value: 120, text: 'Liechtenstein', code: '423' },
  { value: 121, text: 'Lithuania', code: '370' },
  { value: 122, text: 'Luxembourg', code: '352' },
  { value: 123, text: 'Macau', code: '853' },
  { value: 124, text: 'Macedonia', code: '389' },
  { value: 125, text: 'Madagascar', code: '261' },
  { value: 126, text: 'Malawi', code: '265' },
  { value: 127, text: 'Malaysia', code: '60' },
  { value: 128, text: 'Maldives', code: '960' },
  { value: 129, text: 'Mali', code: '223' },
  { value: 130, text: 'Malta', code: '356' },
  { value: 131, text: 'Marshall Islands', code: '692' },
  { value: 132, text: 'Mauritania', code: '222' },
  { value: 133, text: 'Mauritius', code: '230' },
  { value: 134, text: 'Mayotte', code: '262' },
  { value: 135, text: 'Mexico', code: '52' },
  { value: 136, text: 'Micronesia', code: '691' },
  { value: 137, text: 'Moldova', code: '373' },
  { value: 138, text: 'Monaco', code: '377' },
  { value: 139, text: 'Mongolia', code: '976' },
  { value: 140, text: 'Montenegro', code: '382' },
  { value: 141, text: 'Montserrat', code: '1-664' },
  { value: 142, text: 'Morocco', code: '212' },
  { value: 143, text: 'Mozambique', code: '258' },
  { value: 144, text: 'Myanmar', code: '95' },
  { value: 145, text: 'Namibia', code: '264' },
  { value: 146, text: 'Nauru', code: '674' },
  { value: 147, text: 'Nepal', code: '977' },
  { value: 148, text: 'Netherlands', code: '31' },
  { value: 149, text: 'Netherlands Antilles', code: '599' },
  { value: 150, text: 'New Caledonia', code: '687' },
  { value: 151, text: 'New Zealand', code: '64' },
  { value: 152, text: 'Nicaragua', code: '505' },
  { value: 153, text: 'Niger', code: '227' },
  { value: 154, text: 'Nigeria', code: '234' },
  { value: 155, text: 'Niue', code: '683' },
  { value: 156, text: 'North Korea', code: '850' },
  { value: 157, text: 'Northern Mariana Islands', code: '1-670' },
  { value: 158, text: 'Norway', code: '47' },
  { value: 159, text: 'Oman', code: '968' },
  { value: 160, text: 'Pakistan', code: '92' },
  { value: 161, text: 'Palau', code: '680' },
  { value: 162, text: 'Palestine', code: '970' },
  { value: 163, text: 'Panama', code: '507' },
  { value: 164, text: 'Papua New Guinea', code: '675' },
  { value: 165, text: 'Paraguay', code: '595' },
  { value: 166, text: 'Peru', code: '51' },
  { value: 167, text: 'Philippines', code: '63' },
  { value: 168, text: 'Pitcairn', code: '64' },
  { value: 169, text: 'Poland', code: '48' },
  { value: 170, text: 'Portugal', code: '351' },
  { value: 171, text: 'Puerto Rico', code: '1-787, 1-939' },
  { value: 172, text: 'Qatar', code: '974' },
  { value: 173, text: 'Republic of the Congo', code: '242' },
  { value: 174, text: 'Reunion', code: '262' },
  { value: 175, text: 'Romania', code: '40' },
  { value: 176, text: 'Russia', code: '7' },
  { value: 177, text: 'Rwanda', code: '250' },
  { value: 178, text: 'Saint Barthelemy', code: '590' },
  { value: 179, text: 'Saint Helena', code: '290' },
  { value: 180, text: 'Saint Kitts and Nevis', code: '1-869' },
  { value: 181, text: 'Saint Lucia', code: '1-758' },
  { value: 182, text: 'Saint Martin', code: '590' },
  { value: 183, text: 'Saint Pierre and Miquelon', code: '508' },
  { value: 184, text: 'Saint Vincent and the Grenadines', code: '1-784' },
  { value: 185, text: 'Samoa', code: '685' },
  { value: 186, text: 'San Marino', code: '378' },
  { value: 187, text: 'Sao Tome and Principe', code: '239' },
  { value: 188, text: 'Saudi Arabia', code: '966' },
  { value: 189, text: 'Senegal', code: '221' },
  { value: 190, text: 'Serbia', code: '381' },
  { value: 191, text: 'Seychelles', code: '248' },
  { value: 192, text: 'Sierra Leone', code: '232' },
  { value: 193, text: 'Singapore', code: '65' },
  { value: 194, text: 'Sint Maarten', code: '1-721' },
  { value: 195, text: 'Slovakia', code: '421' },
  { value: 196, text: 'Slovenia', code: '386' },
  { value: 197, text: 'Solomon Islands', code: '677' },
  { value: 198, text: 'Somalia', code: '252' },
  { value: 199, text: 'South Africa', code: '27' },
  { value: 200, text: 'South Korea', code: '82' },
  { value: 201, text: 'South Sudan', code: '211' },
  { value: 202, text: 'Spain', code: '34' },
  { value: 203, text: 'Sri Lanka', code: '94' },
  { value: 204, text: 'Sudan', code: '249' },
  { value: 205, text: 'Suriname', code: '597' },
  { value: 206, text: 'Svalbard and Jan Mayen', code: '47' },
  { value: 207, text: 'Swaziland', code: '268' },
  { value: 208, text: 'Sweden', code: '46' },
  { value: 209, text: 'Switzerland', code: '41' },
  { value: 210, text: 'Syria', code: '963' },
  { value: 211, text: 'Tajikistan', code: '992' },
  { value: 212, text: 'Tanzania', code: '255' },
  { value: 213, text: 'Thailand', code: '66' },
  { value: 214, text: 'Togo', code: '228' },
  { value: 215, text: 'Tokelau', code: '690' },
  { value: 216, text: 'Tonga', code: '676' },
  { value: 217, text: 'Trinidad and Tobago', code: '1-868' },
  { value: 218, text: 'Tunisia', code: '216' },
  { value: 219, text: 'Turkey', code: '90' },
  { value: 220, text: 'Turkmenistan', code: '993' },
  { value: 221, text: 'Turks and Caicos Islands', code: '1-649' },
  { value: 222, text: 'Tuvalu', code: '688' },
  { value: 223, text: 'U.S. Virgin Islands', code: '1-340' },
  { value: 224, text: 'Uganda', code: '256' },
  { value: 225, text: 'Ukraine', code: '380' },
  { value: 226, text: 'United Arab Emirates', code: '971' },
  { value: 227, text: 'United Kingdom', code: '44' },
  { value: 228, text: 'United States', code: '1' },
  { value: 229, text: 'Uruguay', code: '598' },
  { value: 230, text: 'Uzbekistan', code: '998' },
  { value: 231, text: 'Vanuatu', code: '678' },
  { value: 232, text: 'Vatican', code: '379' },
  { value: 233, text: 'Venezuela', code: '58' },
  { value: 234, text: 'Vietnam', code: '84' },
  { value: 235, text: 'Wallis and Futuna', code: '681' },
  { value: 236, text: 'Western Sahara', code: '212' },
  { value: 237, text: 'Yemen', code: '967' },
  { value: 238, text: 'Zambia', code: '260' },
  { value: 239, text: 'Zimbabwe', code: '263' }
]

export default countryList
export function getCountryCode (value) {
  return countryList.find(c => c.value === value)?.code
}
