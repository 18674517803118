import apiService from '@/common/api.service'

export function createAutoclave (data) {
  return apiService({
    url: '/api/autoclave',
    method: 'post',
    data
  })
}

export function updateAutoclaveData (data) {
  return apiService({
    url: '/api/autoclave',
    method: 'patch',
    data
  })
}

export function getAutoclaveList (id) {
  return apiService({
    url: '/api/autoclave/list/specify',
    method: 'get',
    params: { id }
  })
}

export function deleteAutoclave (id) {
  return apiService({
    url: '/api/autoclave',
    method: 'delete',
    params: { id }
  })
}
